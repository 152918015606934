<template>
  <div class="cmp-product-price" v-if="showPrices" :class="parentClassRef">
    <ul class="price-list flex-row-nowrap align-items-baseline">
      <li class="default">
        <span>{{ prices.price }}</span>
      </li>
      <li v-if="prices.isSalesAction" class="original salesaction">
        <span>{{ prices.basePrice }}</span>
      </li>
      <!-- BESPOKE ROOD: Added excl label -->
      <li class="excl-label">
        <span v-translation="{ code: 'label_price1_excl_vat', type: 'label'}"></span>
      </li>
      <!-- END BESPOKE ROOD -->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false }
  },
  computed: {
    ...mapGetters(['showPrices'])
  }
}
</script>

<style>

</style>
